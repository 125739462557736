<template>
  <nav>
      <ul>
        <li><a href="#home">HOME</a></li>
        <li><a href="#about">ABOUT</a></li>
        <li><a href="#contact">CONTACT</a></li>
      </ul>
  </nav>
  <div id="particle-area">
    <div id="area">
      <div id="inner-area">
      </div>
    </div>
    <VueParticle domId="area" :config="particleConfig"/>
  </div>
  <div>
      <section id="home" class="base">
          <p class="title">HOME</p>
          <p class="description">
              サイトをご覧いただきありがとうございます。
              <br>
              このサイトはVueでできています。
          </p>
          <img src="../public/html_css_js.png" alt="html_css_js">
      </section>
      <section id="about" class="base">
          <p class="title">ABOUT</p>
          <div>
              <p>このサイトはエックスサーバーに投稿するためのサイトです。</p>
              <br>
              <p>コードは雑、デザインも雑。</p>
              <br>
              <p style="font-size: 40px; font-weight: bold;  margin-top: 100">ただそれが良い。</p>
          </div>
      </section>
      <section id="contact" class="base">
          <p class="title">CONTACT</p>
      </section>
  </div>
</template>

<script>
import VueParticle from 'vue-particlejs';
// import Vue from 'vue';
// Vue.use(VueParticle);

export default {
  name: 'App',
  components: {
    VueParticle
  },
 data() {
    return {
      particleConfig: {
        particles: {
          number: { value: 300, density: { enable: true, value_area: 800 } },
          color: { value: "#ffffff" },
          shape: {
            type: "circle",
            stroke: { width: 0, color: "#000000" },
            polygon: { nb_sides: 5 },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false }
          },
          size: {
            value: 3,
            random: true,
            anim: { enable: false, speed: 40, size_min: 0.1, sync: false }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#ffffff",
            opacity: 0.4,
            width: 1
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: { enable: false, rotateX: 600, rotateY: 1200 }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: { enable: true, mode: "repulse" },
            onclick: { enable: true, mode: "push" },
            resize: true
          },
          modes: {
            grab: { distance: 400, line_linked: { opacity: 1 } },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3
            },
            repulse: { distance: 200, duration: 0.4 },
            push: { particles_nb: 4 },
            remove: { particles_nb: 2 }
          }
        },
        retina_detect: true
      }
    };
  }
}
</script>

<style>
/* Nav */
nav{
  text-align: center;
  margin-bottom: 20px;
}

nav ul{
  margin-top: 20px ;
}

nav li{
   list-style: none;
  display: inline-block;
   width: 10%;
   min-width: 90px;
}

nav li:not(:last-child){
  border-right:2px solid #ddd;
}

nav a{
  text-decoration: none;
  color: #333;
}

nav a.current{
  color: #00B0F0;
  border-bottom:2px solid #00B0F0;
}

nav a:hover{
  color:#F7CB4D;
  border-bottom:2px solid #F7CB4D;
}

/* Img */
img {
  margin: auto;
  width: 500px;
}


/* Section */
.base {
    width: 100%;
    min-height: 400px;
    margin: auto;
    text-align: center;
}

#home {
    background-color: #fff;
}

#about {
    background-color: rgb(250, 250, 250);
}

#contact {
    background-color: #fff;
}

.title {
  font-weight: 600;
  font-size: 30px;
}

.bg {
  background: linear-gradient(-135deg, #fbff27, #d89c41);
  opacity: 50%;
}

h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX( -50% ) translateY( -50% );
  font-size: 60px;
  color: #000;
  z-index: 1;
}

#particle-area {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  width: 800px;
}
#area {
  width: 100%;
  height: 30vh;
  background: #ffd400ba;
}
#inner-area {
  width: 100%;
  height: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 2em;
}
#inner-demo h1 {
  position: relative;
  top: 4em;
}

</style>
